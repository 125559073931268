import React from "react"
import "../assets/style.scss"
import "../assets/timeline.scss"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import journeyLeft from "../images/journeyLeft.svg"
import m1 from "../images/milestones/1.png"
import m2 from "../images/milestones/2.png"
import m3 from "../images/milestones/3.png"
import m4 from "../images/milestones/4.png"
import m5 from "../images/milestones/5.png"
import m6 from "../images/milestones/6.png"
import m7 from "../images/milestones/7.png"
import m8 from "../images/milestones/8.png"
import m9 from "../images/milestones/9.png"
import journey from "../images/journey.svg"
import donate from "../images/Group 13.svg"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import LazyImage from "../components/LazyImage"
import featured_story_low from "../images/featured_story_low.webp"
import AOS from "aos"
import "aos/dist/aos.css"

const images = [m1, m2, m3, m4, m5, m6, m7, m8, m9]

const JourneyPage = ({ data }) => {
  React.useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <SEO title="Our Journey" />
      <Header currentPage="journey" />

      <div style={{ marginTop: "10rem" }} />
      <LazyImage
        lowRes={featured_story_low}
        highRes={journeyLeft}
        alt="journeyLeft"
        className="position-absolute mt-5"
        data-aos="fade-left"
      ></LazyImage>
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-7">
            <h1>{data.strapiOurJourneyPage.title}</h1>

            <br />
            <ReactMarkdown>
              {data.strapiOurJourneyPage.description}
            </ReactMarkdown>
            <br />
          </div>
          <div className="col-lg-5">
            <img src={journey} alt="journey"></img>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <section className="timeline">
        <div className="container">
          {data.strapiOurJourneyPage.Milestone.map((milestone, index) =>
            index % 2 === 0 ? (
              <div className="timeline-item">
                <div className="timeline-img" data-aos="slide-up"></div>

                <div
                  className="timeline-content timeline-card js--fadeInLeft slideshow1left"
                  data-aos="fade-right"
                >
                  <div
                    className="timeline-img-header"
                    style={{
                      background:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url('" +
                        images[index] +
                        "') center center no-repeat",
                    }}
                  ></div>
                  <p>
                    <h3>{milestone.title}</h3>
                    {milestone.description}
                  </p>
                </div>
              </div>
            ) : (
              <div className="timeline-item">
                <div className="timeline-img" data-aos="slide-up"></div>

                <div
                  className="timeline-content timeline-card js--fadeInRight slideshow1right"
                  data-aos="fade-left"
                >
                  <div
                    className="timeline-img-header"
                    style={{
                      background:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url('" +
                        images[index] +
                        "') center center no-repeat",
                    }}
                  ></div>

                  <p>
                    <h3>{milestone.title}</h3>
                    {milestone.description}
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      </section>

      <div className="container" data-aos="fade-up">
        <div
          className="row justify-content-center text-center "
          style={{ marginTop: "-100px" }}
        >
          <div>
            <img src={donate} alt="Donate" />
          </div>
          <div className="col-md-12 my-3">
            <h1>HELP THIS JOURNEY TO GO FAR</h1>
          </div>
          <p className="col-md-6">
            Empower, Educate, and provide for a child in need
          </p>
        </div>
      </div>
      <div className="container mt-2" data-aos="fade-up">
        <div className="row justify-content-center">
          <Link to="/donate" className="m-2">
            <button className="col btn btn-primary">Donate</button>
          </Link>

          <Link to="/partner" className="m-2">
            <button className="col btn btn-success">Be a Partner</button>
          </Link>
        </div>
      </div>
      <br />
      <br />
      <Footer data-aos="fade-right" />
    </div>
  )
}

export default JourneyPage

export const pageQuery = graphql`
  query JourneyQuery {
    strapiOurJourneyPage {
      description
      title
      Milestone {
        description
        id
        image {
          url
          formats {
            small {
              url
            }
            thumbnail {
              url
            }
          }
        }
        title
      }
    }
  }
`
